const exerciseTestListLegs = [
  [
    "Power Jerk",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Squat Jerk",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Split Jerk",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Bodyweight Squat",
    "None",
    "Legs",
    "Quadriceps",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Barbell Back Squat",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Barbell Front Squat",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3-5",
    "6-12",
  ],
  [
    "Barbell Hack Squat",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Barbell Lunge",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Barbell Walking Lunge",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Belt Squat",
    "Belt Squat Machine",
    "Legs",
    "Quadriceps",
    "Beginner-Intermediate",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Bodyweight Lunge",
    "None",
    "Legs",
    "Quadriceps",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Bodyweight Leg Curl",
    "None",
    "Legs",
    "Hamstrings",
    "Intermediate",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Box Squat",
    "Barbell",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Bulgarian Split Squat",
    "Dumbbells",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Chair Squat",
    "Bench",
    "Legs",
    "Quadriceps",
    "Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Dumbbell Lunge",
    "Dumbbells",
    "Legs",
    "Quadriceps",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
];

export default exerciseTestListLegs;
