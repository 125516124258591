import React, { useState } from "react";
import { Link } from "react-router-dom";
// import plan from "../assets/workoutDayPlan";
import exerciseTestList from "../assets/exerciseTestList";

export const RegistrationPage = () => {
  // const timeAvailable = 90;
  // const availableDays = [
  //   "Monday",
  //   "Wednesday",
  //   "Thursday",
  //   "Friday",
  //   "Saturday",
  // ];
  // function timeAvailableIndex(time) {
  //   const result = time > 90 ? 1 : 0;
  //   return result;
  // }
  // const numberOfDays = availableDays.length;
  // const workoutPlan = plan[numberOfDays - 1][timeAvailableIndex(timeAvailable)];
  // function assignDays(keysArray, valuesArray) {
  //   const resultObject = {};
  //   for (let i = 0; i < keysArray.length; i++) {
  //     resultObject[keysArray[i]] = valuesArray[i];
  //   }

  //   return resultObject;
  // }
  // //console.log(assignDays(availableDays, workoutPlan));

  // add warm up for 10 mins
  // get exercise list
  // filter for exercises for back and biceps
  // add compound exercise first in list
  // remove all compound exercises from list

  // add in exercise for back
  // add in exerciser for biceps
  // calculate time remaining - 1 min per set and 2 min rest between set and 3 mins between exercises and 20 mins for warm up and cool down
  // divide time remaining by 12 to get how many 3 rep exercises we can fit in
  // if 1 then add in rear delt if more then add in, in order, to back then biceps then delts and when available a different muscle than already used
  // add cool down for 10 mins

  const [workout, setWorkout] = useState([]);
  const [totTime, setTotTime] = useState(0);
  const [timeAvailable, setTimeAvailable] = useState(90);
  const [expLevel, setExpLevel] = useState("-");

  function clear() {
    setWorkout([]);
    calcTime();
  }
  // function view() {
  //   //console.log(workout);
  // }
  const getTimeLabel = (time) => {
    switch (true) {
      case time > 30 && time <= 40:
        return "0";
      case time > 40 && time <= 50:
        return "5";
      case time > 50:
        return "10";
      default:
        return "0";
    }
  };
  function sumOfSets(nestedArray) {
    let total = 0;

    for (let i = 0; i < nestedArray.length; i++) {
      const entry = nestedArray[i];

      if (typeof entry[6] === "string") {
        total += parseInt(entry[6].split("-")[0]);
      } else {
        total += parseInt(entry[6]);
      }

      //console.log(total);
    }

    return 3 * total;
  }
  const findFirstEntry = (array) => {
    let levels;
    if (expLevel === "Beginner") {
      levels = [
        "Beginner",
        "Beginner-Intermediate",
        "Intermediate",
        "Intermediate-Advanced",
        "Advanced",
      ];
    } else if (expLevel === "Intermediate") {
      levels = [
        "Intermediate",
        "Intermediate-Advanced",
        "Advanced",
        "Beginner-Intermediate",
        "Beginner",
      ];
    } else {
      levels = [
        "Advanced",
        "Intermediate-Advanced",
        "Intermediate",
        "Beginner-Intermediate",
        "Beginner",
      ];
    }
    for (let level of levels) {
      const entry = array.find((item) => item[4] === level);
      if (entry) {
        return entry;
      }
    }
    return null;
  };
  function calcTime() {
    let newTotTime = 0;
    newTotTime = 2 * parseInt(getTimeLabel(timeAvailable)) + workout.length;
    let setsTime = sumOfSets(workout);
    newTotTime += setsTime;
    setTotTime(newTotTime);
  }
  function getCompoundExercise() {
    const compoundExercises = exerciseTestList.filter(
      (exercise) => exercise[1] === "Barbell" && exercise[2] === "Back"
    );
    const firstCompound = findFirstEntry(compoundExercises);
    if (timeAvailable < 40) {
      firstCompound[6]--;
    }
    setWorkout([...workout, firstCompound]);
    calcTime();
  }
  // function addBackExercise() {
  //   const exerciseOptions = exerciseTestList.filter(
  //     (exercise) =>
  //       exercise[1] !== "Barbell" &&
  //       exercise[2] === "Back" &&
  //       !workout.some((w) => JSON.stringify(w) === JSON.stringify(exercise))
  //   );
  //   let newExercise = findFirstEntry(exerciseOptions);
  //   if (timeAvailable < 40) {
  //     newExercise[6]--;
  //   }
  //   setWorkout([...workout, newExercise]);
  // }
  // function addBicepExercise() {
  //   const exerciseOptions = exerciseTestList.filter(
  //     (exercise) =>
  //       exercise[1] !== "Barbell" &&
  //       exercise[3] === "Biceps Brachii" &&
  //       !workout.some((w) => JSON.stringify(w) === JSON.stringify(exercise))
  //   );
  //   let newExercise = findFirstEntry(exerciseOptions);
  //   if (timeAvailable < 40) {
  //     newExercise[6]--;
  //   }
  //   setWorkout([...workout, newExercise]);
  // }
  // function addShoulderExercise() {
  //   const exerciseOptions = exerciseTestList.filter(
  //     (exercise) =>
  //       exercise[1] !== "Barbell" &&
  //       exercise[3] === "Posterior Deltoid" &&
  //       !workout.some((w) => JSON.stringify(w) === JSON.stringify(exercise))
  //   );
  //   let newExercise = findFirstEntry(exerciseOptions);
  //   if (timeAvailable < 40) {
  //     newExercise[6]--;
  //   }
  //   setWorkout([...workout, newExercise]);
  // }

  function addExercise(muscle, group) {
    let exerciseOptions;
    if (group) {
      exerciseOptions = exerciseTestList.filter(
        (exercise) =>
          exercise[1] !== "Barbell" &&
          exercise[2] === muscle &&
          !workout.some((w) => JSON.stringify(w) === JSON.stringify(exercise))
      );
    } else {
      exerciseOptions = exerciseTestList.filter(
        (exercise) =>
          exercise[1] !== "Barbell" &&
          exercise[3] === muscle &&
          !workout.some((w) => JSON.stringify(w) === JSON.stringify(exercise))
      );
    }
    let newExercise = findFirstEntry(exerciseOptions);
    if (timeAvailable < 40) {
      newExercise[6]--;
    }
    //console.log(newExercise);
    setWorkout([...workout, newExercise]);
  }
  function setWorkoutTime(time) {
    setTimeAvailable(time);
  }
  function calcReps(exp) {
    switch (true) {
      case exp === "Beginner":
        return "10-12";
      case exp === "Intermediate":
        return "8-10";
      case exp === "Advanced":
        return "6-8";
      default:
        return "8-12";
    }
  }
  function handleSetExp(exp) {
    setExpLevel(exp);
    let newWorkout = reduceSets(workout, exp);
    setWorkout(newWorkout);
  }
  function reduceSets(arrays, exp) {
    return arrays.map((subArray) => {
      subArray[7] = calcReps(exp); // Decrease the 6th element (index 5) by 1
      return subArray;
    });
  }

  return (
    <div>
      <p>Registration</p>
      <Link to="/" className="btn">
        back to landing page
      </Link>
      <Link to="/registrationPush" className="btn">
        Push Workout
      </Link>
      <Link to="/registrationLegs" className="btn">
        Legs Workout
      </Link>
      <br />
      <Link to="/home" className="btn">
        back to home
      </Link>
      <br />
      <button className="btn" onClick={clear}>
        clear
      </button>
      {/* <button className="btn" onClick={view}>
        view workout
      </button> */}
      <button className="btn" onClick={getCompoundExercise}>
        getCompoundExercise
      </button>
      <button className="btn" onClick={() => addExercise("Back", true)}>
        addBackExercise
      </button>
      <button
        className="btn"
        onClick={() => addExercise("Biceps Brachii", false)}
      >
        addBicepExercise
      </button>
      <button
        className="btn"
        onClick={() => addExercise("Posterior Deltoid", false)}
      >
        addShoulderExercise
      </button>
      <button className="btn" onClick={calcTime}>
        calcTime
      </button>
      <br />
      <button className="btn" onClick={() => setWorkoutTime(30)}>
        30 mins workout
      </button>
      <button className="btn" onClick={() => setWorkoutTime(40)}>
        40 min workout
      </button>
      <button className="btn" onClick={() => setWorkoutTime(50)}>
        50 min workout
      </button>
      <button className="btn" onClick={() => setWorkoutTime(90)}>
        90 min workout
      </button>
      <br />
      <button className="btn" onClick={() => handleSetExp("Beginner")}>
        Beginner
      </button>
      <button className="btn" onClick={() => handleSetExp("Intermediate")}>
        Intermediate
      </button>
      <button className="btn" onClick={() => handleSetExp("Advanced")}>
        Advanced
      </button>
      <div className="container mt-5">
        <h1>Friday (Pull)</h1>
        <h4>
          {totTime} mins ({timeAvailable}), ({expLevel})
        </h4>
        {getTimeLabel(timeAvailable) !== "0" ? (
          <table
            className="table table-bordered"
            style={{ borderColor: "white", color: "white" }}
          >
            <thead>
              <tr>
                <th>warm up</th>
                <th>{getTimeLabel(timeAvailable)} mins</th>
              </tr>
            </thead>
          </table>
        ) : (
          <></>
        )}
        {workout.map((exercise, index) => (
          <table
            key={index}
            className="table table-bordered"
            style={{ borderColor: "white", color: "white" }}
          >
            <thead>
              <tr>
                <th colSpan="4">{exercise[0]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>sets</td>
                <td>reps</td>
                <td>intensity</td>
                <td>rest</td>
              </tr>
              <tr>
                <td>{exercise[6]}</td>
                <td>{exercise[7]}</td>
                <td>6</td>
                <td>{exercise[5]}</td>
              </tr>
            </tbody>
          </table>
        ))}
        {getTimeLabel(timeAvailable) !== "0" ? (
          <table
            className="table table-bordered"
            style={{ borderColor: "white", color: "white" }}
          >
            <thead>
              <tr>
                <th>cool down</th>
                <th>{getTimeLabel(timeAvailable)} mins</th>
              </tr>
            </thead>
          </table>
        ) : (
          <></>
        )}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};
