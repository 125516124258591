// git add .
// git commit -m "launch update"
// git push origin main

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LandingPage } from "./pages/LandingPage.jsx";
import { LoginPage } from "./pages/LoginPage.jsx";
import { HomePage } from "./pages/HomePage.jsx";
import { RegistrationPage } from "./pages/RegistrationPage.jsx";
import { RegistrationPagePush } from "./pages/RegistrationPagePush.jsx";
import { RegistrationPageLegs } from "./pages/RegistrationPageLegs.jsx";
import { TermsPage } from "./pages/TermsPage.jsx";
import { CookiesPage } from "./pages/CookiesPage.jsx";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/registration" element={<RegistrationPage />} />
        <Route path="/registrationPush" element={<RegistrationPagePush />} />
        <Route path="/registrationLegs" element={<RegistrationPageLegs />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/cookies-policy" element={<CookiesPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </Router>
  );
}

export default App;
