import React, { forwardRef, useState, useEffect } from "react";
import heroLogo from "../assets/heroLogo.png";
import heroVid from "../assets/hero.mp4";

export const HeroSection = forwardRef((props, ref) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);
  return (
    <section ref={ref} className="position-relative text-center heroSection">
      <video
        src={heroVid}
        playsInline={true}
        autoPlay={true}
        loop={true}
        muted={true}
        className="img-fluid w-100 heroVideo"
      />
      <img
        src={heroLogo}
        width="60%"
        height="auto"
        alt="heroLogo"
        className={` heroLogo hero-logo position-absolute top-50 start-50 translate-middle ${
          isVisible ? "visible" : ""
        }`}
      />
      <img
        src={heroLogo}
        width="70%"
        height="auto"
        alt="heroLogo"
        className={` heroLogobg1 hero-logo position-absolute top-50 start-50 translate-middle ${
          isVisible ? "visible" : ""
        }`}
      />
      <img
        src={heroLogo}
        width="80%"
        height="auto"
        alt="heroLogo"
        className={` heroLogobg2 hero-logo position-absolute top-50 start-50 translate-middle ${
          isVisible ? "visible" : ""
        }`}
      />
    </section>
  );
});
