import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "../index.css";
import logo from "../assets/logo.png";

export const MyNavbar = (props) => {
  const [navbarOpacity, setNavbarOpacity] = useState("navbarTranslucent");

  useEffect(() => {
    // Copy the current value of heroSectionRef to a local variable
    const currentRef = props.heroSectionRef.current;

    if (currentRef) {
      const observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            setNavbarOpacity("navbarTranslucent"); // Set when the hero section enters the viewport
            //console.log("Hero section is visible");
          } else {
            setNavbarOpacity("navbarOpaque"); // Set when the hero section leaves the viewport
            //console.log("Hero section is not visible");
          }
        },
        { threshold: 0.5 }
      );

      observer.observe(currentRef);

      // Use the local variable in the cleanup function
      return () => {
        observer.unobserve(currentRef);
      };
    }
  }, [props.heroSectionRef]);
  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div
      className={`navContainer  d-flex justify-content-center position-fixed col-12 ${navbarOpacity}`}
    >
      <Navbar expand="lg" className="  py-1 myNav">
        <img
          src={logo}
          width="auto"
          height="33rem"
          className="d-inline-block align-top "
          alt="SPADE logo"
          onClick={() => handleScroll(props.heroSectionRef)}
        />
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <button
              onClick={() => handleScroll(props.productSectionRef)}
              className="navlink border-0"
            >
              Product
            </button>
            <button
              onClick={() => handleScroll(props.faqSectionRef)}
              className="navlink border-0"
            >
              FAQs
            </button>
            <button
              onClick={() => handleScroll(props.contactSectionRef)}
              className="navlink border-0"
            >
              Contact
            </button>
          </Nav>
          {/* <Nav>
            <Link to="/login">
              <button className="mr-2 border-0 navlink">Log In</button>
            </Link>
            <Link to="/registration">
              <button className="signUpBtn ">Sign Up</button>
            </Link>
          </Nav> */}
          <Nav className="d-flex align-items-center">
            <button
              onClick={() => handleScroll(props.waitingListSectionRef)}
              className="signUpBtn "
            >
              Join Us
            </button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
