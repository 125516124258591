const exerciseTestList = [
  [
    "Dumbbell Pullover",
    "Dumbbells",
    "Back",
    "Latissimus Dorsi",
    "Intermediate",
    "120-180",
    "3-5",
    "4-10",
  ],
  [
    "Push-Up Against Wall",
    "Wall",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Barbell Front Raise",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Barbell Rear Delt Row",
    "Barbell",
    "Shoulder",
    "Posterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Barbell Upright Row",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Front Raise",
    "Adjustable Cable Station",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Lateral Raise",
    "Adjustable Cable Station",
    "Shoulder",
    "Lateral Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Rear Delt Fly",
    "Adjustable Cable Station",
    "Shoulder",
    "Posterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Rear Delt Row",
    "Adjustable Cable Station",
    "Shoulder",
    "Posterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Dumbbell Front Raise",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Dumbbell Horizontal Internal Shoulder Rotation",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Dumbbell Lateral Raise",
    "Dumbbells",
    "Shoulder",
    "Lateral Deltoid",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Dumbbell Rear Delt Fly",
    "Dumbbells",
    "Shoulder",
    "Posterior Deltoid",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Dumbbell Rear Delt Row",
    "Dumbbells",
    "Shoulder",
    "Posterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Dumbbell Shoulder Press",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Dumbbell Bent-Over Row",
    "Dumbbells",
    "Back",
    "Latissimus Dorsi",
    "Intermediate",
    "60-120",
    "3",
    "6-12",
  ],
  [
    "Floor Back Extension",
    "None",
    "Back",
    "Erector Spinae",
    "Beginner",
    "60-120",
    "3",
    "8-15",
  ],
  //   [
  //     "Good Morning",
  //     "Barbell",
  //     "Back",
  //     "Erector Spinae",
  //     "Intermediate",
  //     "120-240",
  //     "3",
  //     "4-10",
  //   ],
  [
    "Inverted Row",
    "Barbell",
    "Back",
    "Latissimus Dorsi",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "8-15",
  ],
  [
    "Jefferson Curl",
    "Barbell",
    "Back",
    "Erector Spinae",
    "Advanced",
    "120-240",
    "3",
    "2-8",
  ],
  [
    "Lat Pulldown",
    "Lat Pulldown Machine",
    "Back",
    "Latissimus Dorsi",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Ring Muscle-Up",
    "Gymnastic Rings",
    "Back",
    "Latissimus Dorsi",
    "Advanced",
    "120-240",
    "3",
    "2-8",
  ],
  [
    "Pause Deadlift",
    "Barbell",
    "Back",
    "Erector Spinae",
    "Advanced",
    "120-240",
    "3",
    "2-8",
  ],
  [
    "Pendlay Row",
    "Barbell",
    "Back",
    "Latissimus Dorsi",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Pull-Up",
    "Pull-Up Bar",
    "Back",
    "Latissimus Dorsi",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Ring Pull-Up",
    "Gymnastic Rings",
    "Back",
    "Latissimus Dorsi",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Rack Pull",
    "Barbell",
    "Back",
    "Erector Spinae",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "3-6",
  ],
  [
    "Seal Row",
    "Seal Row Bench",
    "Back",
    "Latissimus Dorsi",
    "Intermediate",
    "120-240",
    "3",
    "4-10",
  ],
  [
    "Seated Machine High Row",
    "Seated Cable Row Machine",
    "Back",
    "Rhomboids",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "6-12",
  ],
  [
    "Seated Machine Low Row",
    "Seated Cable Row Machine",
    "Back",
    "Latissimus Dorsi",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "6-12",
  ],
  [
    "Snatch Grip Deadlift",
    "Barbell",
    "Back",
    "Erector Spinae",
    "Advanced",
    "120-240",
    "3",
    "3-6",
  ],
  [
    "T-Bar Row",
    "Barbell",
    "Back",
    "Latissimus Dorsi",
    "Intermediate",
    "120-240",
    "3",
    "6-12",
  ],
  [
    "Dumbbell Bicep Curl",
    "Dumbbells",
    "Arm",
    "Biceps Brachii",
    "Beginner",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Dumbbell Preacher Curl",
    "Dumbbells",
    "Arm",
    "Biceps Brachii",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Cable Curl With D-Handle",
    "Adjustable Cable Station",
    "Arm",
    "Biceps Brachii",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
];

export default exerciseTestList;
