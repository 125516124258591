import React, { useState } from "react";

export const CookiesPopUp = () => {
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const handleOpenPdf = () => {
    return window.open("/Cookies.pdf", "_blank");
  };

  function handleCookiesAccepted() {
    //console.log("cookiesAccepted");
    setCookiesAccepted(true);
  }
  if (!cookiesAccepted) {
    return (
      <div className="w-100 CookiesSection p-4 d-flex align-items-center justify-content-around">
        <div>
          <h1 className="text-start cookiesTitle">This website uses cookies</h1>
          <p className="text-start">
            We and our partners use cookies and similar tracking technologies on
            this website in order to enhance your browsing experience,
            personalise content and ads and to analyse our traffic.{" "}
            <button
              className="bg-transparent outline-0 border-0 text-warning p-0"
              onClick={() => handleOpenPdf()}
            >
              Learn More
            </button>
          </p>
        </div>
        <button
          className="signUpBtn h-fit-content m-lg-5"
          onClick={handleCookiesAccepted}
        >
          Accept
        </button>
      </div>
    );
  }
  return <></>;
};
