const exerciseTestListPush = [
  [
    "Bar Dip",
    "Parallel Bars",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Cable Chest Press",
    "Adjustable Cable Station",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Close-Grip Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-180",
    "3",
    "4-10",
  ],
  [
    "Close-Grip Feet-Up Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Advanced",
    "120-180",
    "3",
    "4-10",
  ],
  [
    "Decline Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Decline Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Dumbbell Chest Fly",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Intermediate",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Dumbbell Floor Chest Fly",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Intermediate",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Dumbbell Chest Press",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Dumbbell Floor Press",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Dumbbell Decline Chest Press",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Feet-Up Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-180",
    "3",
    "4-10",
  ],
  [
    "Floor Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Incline Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Incline Dumbbell Press",
    "Dumbbells",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Incline Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Kettlebell Floor Press",
    "Kettlebells",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "120-240",
    "3-5",
    "6-15",
  ],
  [
    "Kneeling Incline Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Kneeling Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Machine Chest Fly",
    "Straight-Arm Chest Fly Machine",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Machine Chest Press",
    "Chest Press Machine",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3",
    "4-10",
  ],
  [
    "Pec Deck Machine",
    "Bent-Arm Chest Fly Machine",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Pin Bench Press",
    "Barbell",
    "Chest",
    "Pectoralis Major",
    "Advanced",
    "120-240",
    "3-5",
    "4-8",
  ],
  [
    "Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  [
    "Push-Up Against Wall",
    "Wall",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3-6",
    "5-20",
  ],
  //   [
  //     "Push-Ups With Feet in Rings",
  //     "Gymnastic Rings",
  //     "Chest",
  //     "Pectoralis Major",
  //     "Advanced",
  //     "60-120",
  //     "3-6",
  //     "5-20",
  //   ],
  //   [
  //     "Ring Push-Up",
  //     "Gymnastic Rings",
  //     "Chest",
  //     "Pectoralis Major",
  //     "Advanced",
  //     "60-120",
  //     "3-6",
  //     "5-20",
  //   ],
  [
    "Resistance Band Chest Fly",
    "Resistance Band",
    "Chest",
    "Pectoralis Major",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Smith Machine Bench Press",
    "Smith Machine",
    "Chest",
    "Pectoralis Major",
    "Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Smith Machine Incline Bench Press",
    "Smith Machine",
    "Chest",
    "Pectoralis Major",
    "Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Standing Cable Chest Fly",
    "Adjustable Cable Station",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Barbell Front Raise",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Barbell Upright Row",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Front Raise",
    "Adjustable Cable Station",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Cable Lateral Raise",
    "Adjustable Cable Station",
    "Shoulder",
    "Lateral Deltoid",
    "Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Dumbbell Front Raise",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Dumbbell Horizontal Internal Shoulder Rotation",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Dumbbell Lateral Raise",
    "Dumbbells",
    "Shoulder",
    "Lateral Deltoid",
    "Beginner",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Dumbbell Shoulder Press",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Front Hold",
    "Weight Plate",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "60-120",
    "3",
    "4-10",
  ],
  [
    "Machine Lateral Raise",
    "Lateral Raise Machine",
    "Shoulder",
    "Lateral Deltoid",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Machine Shoulder Press",
    "Shoulder Press Machine",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner-Intermediate",
    "120-180",
    "3-5",
    "4-10",
  ],
  [
    "Barbell Overhead Press",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Kettlebell Shoulder Press",
    "Kettlebells",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "60-120",
    "3",
    "10-20",
  ],
  [
    "Plate Front Raise",
    "Weight Plate",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner-Intermediate",
    "60-120",
    "3-5",
    "6-15",
  ],
  [
    "Push Press",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Seated Dumbbell Front Raise",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Seated Dumbbell Lateral Raise",
    "Dumbbells",
    "Shoulder",
    "Lateral Deltoid",
    "Beginner",
    "60-120",
    "3",
    "6-15",
  ],
  [
    "Seated Dumbbell Shoulder Press",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Seated Barbell Overhead Press",
    "Dumbbells",
    "Shoulder",
    "Anterior Deltoid",
    "Intermediate-Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Seated Smith Machine Shoulder Press",
    "Smith Machine",
    "Shoulder",
    "Anterior Deltoid",
    "Beginner-Intermediate",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Snatch Grip Behind the Neck Press",
    "Barbell",
    "Shoulder",
    "Anterior Deltoid",
    "Advanced",
    "120-240",
    "3-5",
    "4-10",
  ],
  [
    "Barbell Standing Triceps Extension",
    "Barbell",
    "Arm",
    "Triceps Brachii",
    "Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Barbell Lying Triceps Extension",
    "Barbell",
    "Arm",
    "Triceps Brachii",
    "Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Bench Dip",
    "Bench",
    "Arm",
    "Triceps Brachii",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "5-15",
  ],
  [
    "Close-Grip Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "5-15",
  ],
  [
    "Diamond Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Intermediate",
    "60-120",
    "3",
    "5-15",
  ],
  [
    "Dumbbell Lying Triceps Extension",
    "Dumbbells",
    "Arm",
    "Triceps Brachii",
    "Intermediate-Advanced",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Dumbbell Standing Triceps Extension",
    "Dumbbells",
    "Arm",
    "Triceps Brachii",
    "Intermediate-Advanced",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Overhead Cable Triceps Extension",
    "Adjustable Cable Station",
    "Arm",
    "Triceps Brachii",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Tricep Bodyweight Extension",
    "Parallel Bars",
    "Arm",
    "Triceps Brachii",
    "Intermediate",
    "60-120",
    "3",
    "5-15",
  ],
  [
    "Tricep Pushdown With Bar",
    "Adjustable Cable Station",
    "Arm",
    "Triceps Brachii",
    "Beginner",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Tricep Pushdown With Rope",
    "Adjustable Cable Station",
    "Arm",
    "Triceps Brachii",
    "Beginner",
    "60-120",
    "3",
    "8-12",
  ],
  [
    "Wide Push-Up",
    "null",
    "Chest",
    "Pectoralis Major",
    "Beginner-Intermediate",
    "60-120",
    "3",
    "5-15",
  ],
  [
    "Jumping Muscle-Up",
    "Pull-Up Bar",
    "Chest",
    "Pectoralis Major",
    "Intermediate-Advanced",
    "120-240",
    "3",
    "2-8",
  ],
  [
    "Muscle-Up",
    "Pull-Up Bar",
    "Chest",
    "Pectoralis Major",
    "Advanced",
    "120-240",
    "3",
    "2-8",
  ],
];

export default exerciseTestListPush;
