// import React from "react";
// import { Link } from "react-router-dom";

// export const LoginPage = () => {
//   return (
//     <div>
//       <p>LoginPage</p>
//       <Link to="/">back to landing page</Link>
//       <br />
//       <Link to="/home">login</Link>
//     </div>
//   );
// };

import React, { useEffect, useRef } from "react";

export const LoginPage = () => {
  const sectionsRef = useRef([]);
  const isScrolling = useRef(false);

  const scrollToSection = (direction) => {
    const viewportCenter = window.innerHeight / 2;

    const currentSectionIndex = sectionsRef.current.findIndex((section) => {
      const rect = section.getBoundingClientRect();
      return rect.top <= viewportCenter && rect.bottom > viewportCenter;
    });

    if (currentSectionIndex === -1) return;

    if (
      direction === "down" &&
      currentSectionIndex < sectionsRef.current.length - 1
    ) {
      sectionsRef.current[currentSectionIndex + 1].scrollIntoView({
        behavior: "smooth",
      });
    } else if (direction === "up" && currentSectionIndex > 0) {
      sectionsRef.current[currentSectionIndex - 1].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleScroll = (e) => {
    if (isScrolling.current) return;

    e.preventDefault();
    isScrolling.current = true;

    if (e.deltaY > 0) {
      scrollToSection("down");
    } else if (e.deltaY < 0) {
      scrollToSection("up");
    }

    setTimeout(() => {
      isScrolling.current = false;
    }, 1700); // Adjust timeout to the duration of smooth scroll
  };

  useEffect(() => {
    window.addEventListener("wheel", handleScroll, { passive: false });

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  return (
    <div>
      {[...Array(5).keys()].map((_, index) => (
        <section
          key={index}
          ref={(el) => (sectionsRef.current[index] = el)}
          style={{
            height: "100vh",
            scrollSnapAlign: "start",
            backgroundColor: index % 2 === 0 ? "lightblue" : "lightgreen",
          }}
        >
          Section {index + 1}
        </section>
      ))}
    </div>
  );
};
