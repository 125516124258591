import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../assets/logo.png";
import { Footer } from "../components/Footer";

export const PrivacyPolicyPage = () => {
  return (
    <div>
      <div className={`navContainer  d-flex justify-content-center col-12`}>
        <Navbar expand="lg" className=" px-4 py-1 myNav">
          <Link to="/">
            <img
              src={logo}
              width="auto"
              height="33rem"
              className="d-inline-block align-top "
              alt="SPADE logo"
            />
          </Link>
        </Navbar>
      </div>
      <div className="min-vh-100">
        <h1>Privacy Policy</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nobis
          perspiciatis nulla et quia deleniti ducimus ea fugiat, similique ut
          delectus, quasi excepturi corrupti iste doloribus vero, ipsa sapiente
          aut dolorum?
        </p>
      </div>
      <Footer />
    </div>
  );
};
