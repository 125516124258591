import React, { useState, forwardRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

export const ContactSection = forwardRef((props, ref) => {
  const [email, setEmail] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [messageSent, setMessageSent] = useState(false);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    message: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail((prevEmail) => ({
      ...prevEmail,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {
      firstName: email.firstName === "",
      lastName: email.lastName === "",
      email: !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.email),
      message: email.message === "",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmitEmail = () => {
    if (!validateForm()) {
      return;
    }

    // Fetch POST request to your API endpoint
    fetch("https://api.spade.fit/api/waiting-list-contact-form", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        //console.log("Success:", data);
        setEmail({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
        setMessageSent(true);
        setTimeout(() => setMessageSent(false), 3000); // Hide message after 3 seconds
      })
      .catch((error) => {
        console.error("Error:", error);
        // Optionally, handle the error (e.g., display an error message to the user)
      });
  };

  return (
    <section ref={ref} className="contactSection">
      <div className="w-100 h-100 text-center d-flex justify-content-center flex-column align-items-center">
        <h1 className="waitingListTitle mb-0 mt-0">CONTACT US</h1>
        <div className="form rounded-4 shadow-sm">
          <div className="row mb-3">
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="firstName"
                placeholder="First name*"
                className={`form-control rounded-0 px-0 ${
                  errors.firstName ? "border-danger" : ""
                }`}
                value={email.firstName}
                onChange={handleChange}
              />
              {errors.firstName && (
                <div className="text-danger">Please enter your first name.</div>
              )}
            </div>
            <div className="col-12 col-md-6 mb-3">
              <input
                type="text"
                name="lastName"
                placeholder="Last name*"
                className={`form-control rounded-0 px-0 ${
                  errors.lastName ? "border-danger" : ""
                }`}
                value={email.lastName}
                onChange={handleChange}
              />
              {errors.lastName && (
                <div className="text-danger">Please enter your last name.</div>
              )}
            </div>
            <div className="col-12 mb-3">
              <input
                type="email"
                name="email"
                placeholder="Email*"
                className={`form-control rounded-0 px-0 ${
                  errors.email ? "border-danger" : ""
                }`}
                value={email.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="text-danger">
                  Please enter a valid email address.
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <label htmlFor="message" className="form-label text-start w-100">
                Message*
              </label>
              <textarea
                name="message"
                id="message"
                className={`form-control rounded-3 ${
                  errors.message ? "border-danger" : ""
                }`}
                placeholder="Message*"
                value={email.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <div className="text-danger">Please enter a message.</div>
              )}
            </div>
          </div>
          <button
            onClick={handleSubmitEmail}
            className={`signUpBtn px-5 fw-bold m-2 ${
              messageSent ? "successBtn" : ""
            }`}
          >
            {messageSent ? "Message sent!" : "Confirm"}
          </button>
        </div>
      </div>
    </section>
  );
});
