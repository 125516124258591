import React, { forwardRef } from "react";

export const FAQSection = forwardRef((props, ref) => {
  return (
    <section ref={ref} className="faqSection">
      <div className="section text-center d-flex justify-content-center flex-column align-items-center w-100 h-100 bgOverlay">
        <div className="container mb-5 FAQContainer">
          <h1 className="FAQTitle mb-5 w-100">FAQ</h1>
          <div className="row">
            <div className="col-md-6">
              <div className="accordion" id="faqAccordionLeft">
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingOneLeft">
                    <button
                      className="accordion-button faq-header rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneLeft"
                      aria-expanded="false"
                      aria-controls="collapseOneLeft"
                    >
                      What does Spade do?
                    </button>
                  </h2>
                  <div
                    id="collapseOneLeft"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneLeft"
                    data-bs-parent="#faqAccordionLeft"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      At Spade, we craft
                      <span className="fw-bold"> personalised</span> workout
                      programmes that are uniquely tailored to your goals, for
                      all experience levels. Whether you’re working full-time,
                      studying for your finals or just looking to switch up your
                      training style altogether, let us take some work off your
                      plate.
                      <br /> <br />
                      Think of us as your dedicated training partner –
                      <span className="fw-bold">
                        {" "}
                        We actively monitor your progress and tweak your plan
                        weekly,
                      </span>{" "}
                      ensuring you stay on track to achieve your fitness
                      targets.
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingTwoLeft">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoLeft"
                      aria-expanded="false"
                      aria-controls="collapseTwoLeft"
                    >
                      Is Spade for me?
                    </button>
                  </h2>
                  <div
                    id="collapseTwoLeft"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwoLeft"
                    data-bs-parent="#faqAccordionLeft"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      Whether you’ve been hitting the gym for a decade, or just
                      for a day, Spade is designed for you. We offer exercises
                      and programming breakdowns for skill levels and goals
                      across the spectrum.
                      <br />
                      <br />
                      At Spade, we know how arduous it can be to write a workout
                      programme from scratch, especially when you’re juggling a
                      busy schedule. That's why Spade is perfect for anyone who
                      wants an{" "}
                      <span className="fw-bold">
                        efficient, personalised workout without the hassle.
                      </span>
                      <br />
                      <br />
                      Experienced lifters understand how valuable a
                      well-structured training regimen is to keep you on track,
                      but life has a way of disrupting even the best routines.{" "}
                      <span className="fw-bold">That’s where we come in</span> –
                      Spade will handle all the planning, strategy and
                      monitoring, creating a fully customised routine that's
                      adaptable to shifts in your schedule, environment, or even
                      the dreaded 'I' word (injuries). <br />
                      <br />
                      Our aim is to remove any obstacles standing between you
                      and victory. Let us handle the logistics, so you can{" "}
                      <span className="fw-bold">
                        focus on the hard part: crushing your workouts!
                      </span>
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingThreeLeft">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeLeft"
                      aria-expanded="false"
                      aria-controls="collapseThreeLeft"
                    >
                      How does Spade help me reach my goal?
                    </button>
                  </h2>
                  <div
                    id="collapseThreeLeft"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeLeft"
                    data-bs-parent="#faqAccordionLeft"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      In many ways,{" "}
                      <span className="fw-bold">Spade has the easy job</span> –
                      Yes, we write the routine and track your progress. But
                      ultimately, it’s up to you to execute the plan and push
                      yourself to improve.
                      <br /> <br /> That’s where goal-setting comes in. Your
                      goals need to be
                      <span className="fw-bold">
                        {" "}
                        ‘SMART’ – Specific, Measurable, Achievable, Relevant,
                        and Time-Bound.
                      </span>{" "}
                      When you sign up, we’ll calculate a score indicating the
                      likelihood of hitting your goal, and adjust it weekly
                      based on your progress. If your goal seems unrealistic,
                      we’ll even suggest optional adjustments to your timeline
                      and programme to pave a smoother road to your destination.
                      But remember, you’re the one who makes the journey!
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingFourLeft">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourLeft"
                      aria-expanded="false"
                      aria-controls="collapseFourLeft"
                    >
                      Should I pair Spade with a real coach or PT?
                    </button>
                  </h2>
                  <div
                    id="collapseFourLeft"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFourLeft"
                    data-bs-parent="#faqAccordionLeft"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      Pairing Spade with a real coach would be a great strategy
                      to{" "}
                      <span className="fw-bold">
                        boost your results even further.
                      </span>{" "}
                      Trainers have a tough job and are typically very busy
                      training multiple clients simultaneously. As a result,
                      it’s really challenging for them to programme each client
                      with the same level of detail and precision – that’s where
                      Spade can help!{" "}
                      <span className="fw-bold">We are specialists</span> in
                      workout planning and our approach has been strategised by
                      a best-in-class team of science-minded experts.
                      <br />
                      <br />{" "}
                      <span className="fw-bold">
                        Think of it like this:
                      </span>{" "}
                      Spade will write you a specialised regimen that factors in
                      all of the complex considerations around your equipment,
                      schedule, goals, recovery needs and any injuries. Your
                      coach can then focus on fine-tuning your technique,
                      strategising your programme customisations, and giving you
                      that extra push when things get tough.
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingFiveLeft">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveLeft"
                      aria-expanded="false"
                      aria-controls="collapseFiveLeft"
                    >
                      How fast can I reach my goals with Spade?
                    </button>
                  </h2>
                  <div
                    id="collapseFiveLeft"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFiveLeft"
                    data-bs-parent="#faqAccordionLeft"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start ">
                      We get it – everyone wants quick results. However, the
                      fitness industry’s promises of rapid transformations often
                      lead to disappointment and burnout. At Spade,{" "}
                      <span className="fw-bold">
                        we believe in building sustainable training habits
                      </span>{" "}
                      that fit into your lifestyle, alongside your diet, sleep,
                      social life, and stress management.
                      <br />
                      <br />
                      Often, the fastest route to your goal isn’t the shortest
                      one – it takes steady, consistent and intentional steps
                      forward. By focusing on gradual improvements, you'll
                      develop habits that last a lifetime. It’s not just about
                      speed, it’s about sustainability. We’re here to help you
                      reach and maintain your goals for the long haul.
                      <br />
                      <br /> With that being said, Spade tracks your progress
                      week-by-week and makes tactical adjustments to your
                      exercise selection, volume and intensity in order to
                      ensure you{" "}
                      <span className="fw-bold">
                        hit your target as fast and as safely as possible!
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="faqAccordionRight">
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingOneRight">
                    <button
                      className="accordion-button faq-header rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOneRight"
                      aria-expanded="false"
                      aria-controls="collapseOneRight"
                    >
                      Can I customise my workout with Spade?
                    </button>
                  </h2>
                  <div
                    id="collapseOneRight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOneRight"
                    data-bs-parent="#faqAccordionRight"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      <span className="fw-bold">Yes!</span> We know schedules
                      sometime change unpredictably, so you can adjust the
                      routine to fit your lifestyle. If your workout feels too
                      easy or too tough, we can even tweak the intensity to
                      match. If you want to switch up some exercises, we'll
                      provide you with{" "}
                      <span className="fw-bold">
                        unfiltered access to our extensive library of exercises.
                      </span>{" "}
                      <br />
                      <br /> In order to ensure your programme receives the{" "}
                      <span className="fw-bold">
                        deepest level of personalisation possible,
                      </span>{" "}
                      our exercise library has been carefully curated by leading
                      researchers across biomechanics, neurophysiology and
                      sports science from top universities in the UK.{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingTwoRight">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwoRight"
                      aria-expanded="false"
                      aria-controls="collapseTwoRight"
                    >
                      Can I use Spade without a real coach or PT?
                    </button>
                  </h2>
                  <div
                    id="collapseTwoRight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwoRight"
                    data-bs-parent="#faqAccordionRight"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      <span className="fw-bold"> Absolutely!</span> Spade is all
                      about making
                      <span className="fw-bold">
                        {" "}
                        top-notch workout programming accessible to everyone.
                      </span>{" "}
                      We know that not everyone can spend £50 an hour on a
                      strength coach, but with Spade, you get a deeply
                      personalised and tracked workout plan at a fraction of
                      that hourly cost... For the entire month!
                      <br />
                      <br />
                      In case we forgot to mention, our programming is informed
                      by the best coaches around, so you're getting premium
                      guidance at a fraction of the cost. Your subscription also
                      includes access to our extensive exercise library,
                      specially curated by expert researchers, filled with
                      technique guidance videos, explanations and training
                      modality alternatives to keep things fresh.
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingThreeRight">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThreeRight"
                      aria-expanded="false"
                      aria-controls="collapseThreeRight"
                    >
                      What equipment do I need to use Spade?
                    </button>
                  </h2>
                  <div
                    id="collapseThreeRight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThreeRight"
                    data-bs-parent="#faqAccordionRight"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      Spade is super flexible and works with whatever equipment
                      you have on hand. Training at an Olympic facility? Great!
                      Just a couple of dumbbells at home? Perfect! Only your
                      body weight? That works too!
                      <br /> <br /> During your initial setup, we’ll ask for a
                      list of your available equipment to create a routine that
                      fits.
                      <span className="fw-bold">
                        {" "}
                        No fancy gear needed – just your commitment to the
                        programme.
                      </span>{" "}
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingFourRight">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFourRight"
                      aria-expanded="false"
                      aria-controls="collapseFourRight"
                    >
                      How does integration work with wearables?
                    </button>
                  </h2>
                  <div
                    id="collapseFourRight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFourRight"
                    data-bs-parent="#faqAccordionRight"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      Spade’s biometric analysis feature is
                      <span className="fw-bold">
                        {" "}
                        compatible with all major smart watch/fitness tracker
                        brands.
                      </span>{" "}
                      You can delegate access to us in order to begin tracking
                      your biometric statistics, such as heart rate, sleep
                      patterns, and activity levels.
                      <br />
                      <br />
                      We use this data to assess your fitness level and
                      progress, enabling us to fine-tune your workout programme
                      even more precisely.
                    </div>
                  </div>
                </div>
                <div className="accordion-item faq-card rounded-4 mb-3">
                  <h2 className="accordion-header" id="headingFiveRight">
                    <button
                      className="accordion-button faq-header  rounded-4 collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFiveRight"
                      aria-expanded="false"
                      aria-controls="collapseFiveRight"
                    >
                      What is unique to Spade?
                    </button>
                  </h2>
                  <div
                    id="collapseFiveRight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFiveRight"
                    data-bs-parent="#faqAccordionRight"
                  >
                    <div className="accordion-body rounded-4 faq-body text-start">
                      At Spade, we know that variety is important, but we don't
                      let it define us. We dive deep into your goals,
                      environment, strengths and weaknesses to create a workout
                      routine that’s
                      <span className="fw-bold">
                        {" "}
                        uniquely yours.
                        <br />
                      </span>{" "}
                      <br /> We review your progress by parsing through journal
                      entries and integrating your biometric health statistics
                      from your smart watch or fitness tracker. From there, we
                      make small tweaks to your plan each week to deliver
                      consistent improvements throughout the programme.
                      <br />
                      <br />
                      The result?
                      <span className="fw-bold">
                        {" "}
                        An elite-level training experience that’s entirely
                        dedicated to you, and only you.
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
});
