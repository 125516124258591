import React, { useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { MyNavbar } from "../components/Navbar";
import { ContactSection } from "../sections/ContactSection";
import { HeroSection } from "../sections/HeroSection";
import { ProductSection } from "../sections/ProductSection";
import { WaitingListSection } from "../sections/WaitingListSection";
import { QuestionsSection } from "../sections/QuestionsSection";
import { FAQSection } from "../sections/FAQSection";
import { Footer } from "../components/Footer";
import { CarouselSection } from "../sections/CarouselSection";
import { CookiesPopUp } from "../components/CookiesPopUp";
// import { Separator } from "../components/Separator";
// import { ProductSectionB } from "../sections/ProductSectionB";

export const LandingPage = () => {
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const heroSectionRef = useRef(null);
  const productSectionRef = useRef(null);
  const faqSectionRef = useRef(null);
  const waitingListSectionRef = useRef(null);
  const contactSectionRef = useRef(null);
  const carouselSectionRef = useRef(null);
  return (
    <div>
      <MyNavbar
        heroSectionRef={heroSectionRef}
        productSectionRef={productSectionRef}
        faqSectionRef={faqSectionRef}
        waitingListSectionRef={waitingListSectionRef}
        carouselSectionRef={carouselSectionRef}
        contactSectionRef={contactSectionRef}
      />
      <HeroSection ref={heroSectionRef} />
      <ProductSection
        ref={productSectionRef}
        waitingListSectionRef={waitingListSectionRef}
      />
      {/* <Separator /> */}
      {/* <ProductSectionB /> */}
      <CarouselSection ref={carouselSectionRef} />
      {!hasSignedUp ? (
        <WaitingListSection
          ref={waitingListSectionRef}
          onSignUp={() => setHasSignedUp(true)}
        />
      ) : (
        <QuestionsSection ref={waitingListSectionRef} />
      )}
      <FAQSection ref={faqSectionRef} />
      <ContactSection ref={contactSectionRef} />
      <Footer />
      <CookiesPopUp />
    </div>
  );
};
