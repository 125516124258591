import React, { useState, forwardRef, useRef, useEffect } from "react";
import questionsList from "../assets/questions.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare as faSolidSquare } from "@fortawesome/free-solid-svg-icons";
import { faSquare as faRegularSquare } from "@fortawesome/free-regular-svg-icons";
import { ProgressBar } from "react-bootstrap";

export const QuestionsSection = forwardRef((props, ref) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [customResponses, setCustomResponses] = useState({});
  const [history, setHistory] = useState([0]);
  const [isFinished, setIsFinished] = useState(false);
  const questionRef = useRef(null);
  const questionRef2 = useRef(null);

  //
  const progress = (currentQuestionIndex / (18 - 1)) * 100;

  //

  const handleAnswer = (answer) => {
    const currentQuestion = questionsList[currentQuestionIndex];

    // Handling multiple selections
    if (currentQuestion.allowMultiple) {
      const updatedAnswers = responses[currentQuestionIndex]
        ? [...responses[currentQuestionIndex]]
        : [];
      const answerIndex = updatedAnswers.indexOf(answer);
      if (answerIndex >= 0) {
        updatedAnswers.splice(answerIndex, 1);
      } else {
        updatedAnswers.push(answer);
      }
      setResponses({
        ...responses,
        [currentQuestionIndex]: updatedAnswers,
      });
    } else {
      setResponses({
        ...responses,
        [currentQuestionIndex]: [answer],
      });
    }
  };

  const handleCustomInput = (e) => {
    setCustomResponses({
      ...customResponses,
      [currentQuestionIndex]: e.target.value,
    });
  };

  const handleNext = () => {
    //console.log("handleNext", questionRef);
    if (currentQuestionIndex >= questionsList.length - 1) {
      handleSubmit();
      return;
    }
    const currentResponse = responses[currentQuestionIndex];
    if (!currentResponse || currentResponse.length === 0) return; // Prevent next if no answer

    const currentQuestion = questionsList[currentQuestionIndex];
    const nextIndex =
      currentQuestion.nextQuestionExceptions &&
      currentResponse in currentQuestion.nextQuestionExceptions
        ? currentQuestion.nextQuestionExceptions[currentResponse]
        : currentQuestion.nextQuestionDefault;

    if (nextIndex !== undefined && nextIndex < questionsList.length) {
      setCurrentQuestionIndex(nextIndex);
      setHistory([...history, nextIndex]);
    }
    // questionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentQuestionIndex]);

  useEffect(() => {
    if (questionRef2.current) {
      questionRef2.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFinished]);

  const handlePrev = () => {
    if (history.length > 1) {
      history.pop();
      setCurrentQuestionIndex(history[history.length - 1]);
      setHistory([...history]);
    }
  };

  const handleSubmit = async () => {
    //console.log(responses);
    try {
      // const response = await fetch("http://localhost:3000/api/responses", {
      const response = await fetch(
        "https://api.spade.fit/api/waiting-list-responses",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(responses), // Sending the entire responses object
        }
      );

      if (response.ok) {
        //console.log("Responses submitted successfully");
        setIsFinished(true); // Set isFinished to true to show the thank-you message
        // questionRef2.current.scrollIntoView({ behavior: "smooth" });
      } else {
        throw new Error("Failed to submit responses");
      }
    } catch (error) {
      console.error("Submit error:", error);
    }
  };

  if (isFinished) {
    return (
      <section
        ref={ref}
        className="questionSection text-center py-lg-5 d-flex justify-content-center flex-column align-items-center"
      >
        <h1 className="waitingListTitle " ref={questionRef2}>
          JOIN THE COMMUNITY
        </h1>
        <div className="waitingListContainer border border-white rounded-5 text-center py-5 d-flex justify-content-center flex-column align-items-center">
          <h1 className="waitingListSubtitle mb-2">
            Thank you for completing the questionnaire!
          </h1>
          <small className="waitingListSmall">
            Your seat on our waiting list is confirmed!
            <br /> We'll be in touch pre-launch to get you exclusive early
            access.
          </small>
        </div>
      </section>
    );
  }

  const currentQuestion = questionsList[currentQuestionIndex];
  const optionsExcludingOther = currentQuestion.answers.filter(
    (answer) => !answer.includes("Other:")
  );

  return (
    <section
      ref={ref}
      className="questionSection text-center py-lg-5 d-flex justify-content-center flex-column align-items-center"
    >
      <h1 className="waitingListTitle " ref={questionRef}>
        JOIN THE COMMUNITY
      </h1>
      <div className="waitingListContainer border border-white rounded-5 text-center py-5 d-flex justify-content-center flex-column align-items-center">
        <h1 className="waitingListSubtitle mb-2 mt-2">
          {currentQuestion.question}
        </h1>
        {currentQuestion.allowMultiple === true ? (
          <small className="mt-4">(Please select all which apply)</small>
        ) : null}
        <div className="container col-11">
          <div className="mt-5 row justify-content-center">
            {currentQuestion.answers.map((answer, index) => (
              <div
                className={`text-start mb-3 ${
                  answer.includes("Other:")
                    ? "col-12"
                    : optionsExcludingOther.length === 3
                    ? "col-lg-4 col-md-6 col-sm-12"
                    : "col-lg-6 col-md-6 col-sm-12"
                }`}
                key={index}
              >
                <div
                  className="border-0 outline-0 p-3 w-100 text-start rounded-3 answerOptions d-flex align-items-center justify-content-between"
                  onClick={() => handleAnswer(answer)}
                >
                  {answer}
                  {answer.includes("Other:") && (
                    <input
                      className="form-control border-0 outline-0 text-start answerInput"
                      type="text"
                      value={customResponses[currentQuestionIndex] || ""}
                      onChange={handleCustomInput}
                      placeholder="Please specify..."
                    />
                  )}
                  <FontAwesomeIcon
                    icon={
                      responses[currentQuestionIndex] &&
                      responses[currentQuestionIndex].includes(answer)
                        ? faSolidSquare
                        : faRegularSquare
                    }
                    className="me-2 glow"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="mt-4">
          <button
            className="signUpBtn me-2"
            onClick={handlePrev}
            disabled={history.length <= 1}
          >
            Back
          </button>
          <button
            className="btn signUpBtn"
            onClick={handleNext}
            disabled={
              !(currentQuestionIndex in responses) ||
              responses[currentQuestionIndex].length === 0
            }
          >
            {currentQuestionIndex === questionsList.length - 1
              ? "Finish"
              : "Next"}
          </button>
        </div> */}
        <div className="mt-4 d-flex flex-column align-items-center">
          <button
            className={`mb-2 signUpBtn ${
              !responses[currentQuestionIndex] ||
              responses[currentQuestionIndex].length === 0
                ? "opacity-50"
                : ""
            }`}
            onClick={handleNext}
            disabled={
              !responses[currentQuestionIndex] ||
              responses[currentQuestionIndex].length === 0
            }
          >
            Confirm
          </button>
          <div className="d-flex align-items-center">
            <button
              className="navlink border-0"
              onClick={handlePrev}
              disabled={history.length <= 1}
            >
              &lt;
            </button>
            <div className="navigation-buttons">
              <ProgressBar
                now={progress}
                className="mx-3 custom-progress-bar"
              />
            </div>

            <button
              className={`navlink border-0 ${
                !responses[currentQuestionIndex] ||
                responses[currentQuestionIndex].length === 0
                  ? "opacity-50"
                  : ""
              }`}
              onClick={handleNext}
              disabled={
                !responses[currentQuestionIndex] ||
                responses[currentQuestionIndex].length === 0
              }
            >
              &gt;
            </button>
          </div>
        </div>
      </div>
    </section>
  );
});
