import React from "react";
import { Link } from "react-router-dom";

export const HomePage = () => {
  return (
    <div>
      <p>logged in</p>
      <Link to="/login">logout</Link>
      <br />
      <Link to="/registration">start registration</Link>
    </div>
  );
};
