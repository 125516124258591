import React, { useEffect, useRef } from "react";
import promoVid from "../assets/promoVid.mp4";

export const CarouselSection = () => {
  const productPointsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
            observer.unobserve(entry.target); // Stop observing once the animation is triggered
          }
        });
      },
      { threshold: 0.5 } // Trigger when 50% of the element is in view
    );

    productPointsRef.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      productPointsRef.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div className="carouselSection text-center  d-flex justify-content-center flex-column align-items-center">
      <div
        className="productPoint"
        ref={(el) => (productPointsRef.current[0] = el)}
      >
        <h1 className="productTitleB">BESPOKE</h1>
        <p>
          Workout programmes expertly tailored to fit your personal lifestyle,
          goals and preferences.
        </p>
      </div>
      <div
        className="productPoint"
        ref={(el) => (productPointsRef.current[1] = el)}
      >
        <h1 className="productTitleB">DYNAMIC</h1>
        <p>Evolving routines that adapt weekly based on your performance.</p>
      </div>
      <div
        className="productPoint"
        ref={(el) => (productPointsRef.current[2] = el)}
      >
        <h1 className="productTitleB">ELITE</h1>
        <p>
          Training philosophies shaped by world-class athletes, coaches and
          researchers.
        </p>
      </div>
      <video
        src={promoVid}
        playsInline={true}
        autoPlay={true}
        loop={true}
        muted={true}
        width="100%"
      />
    </div>
  );
};
