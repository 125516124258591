import React, { forwardRef } from "react";

export const ProductSection = forwardRef((props, ref) => {
  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <section
      ref={ref}
      className="productSection section text-center d-flex flex-column align-items-center"
    >
      <div className="section justify-content-center text-center d-flex flex-column align-items-center w-100 h-100 bgOverlay">
        {/* <img src={bgIcon1} alt="" className="bgIcon icon1" />
        <img src={bgIcon2} alt="" className="bgIcon icon2" />
        <img src={bgIcon3} alt="" className="bgIcon icon3" />
        <img src={bgIcon4} alt="" className="bgIcon icon4" />
        <img src={bgIcon5} alt="" className="bgIcon icon5" /> */}
        <h1 className="productTitle mb-3">TRAINING PLANS LIKE NEVER BEFORE</h1>
        <p className="productText mb-5">
          Uniquely crafted workout programmes, dynamically adaptive to your
          personal performance and lifestyle  
        </p>
        <div className="position-relative">
          <button
            className="btn startBtn"
            onClick={() => handleScroll(props.waitingListSectionRef)}
          >
            Start Now
          </button>
          <button className="btn startBtnIndentInner"></button>
          <button className="btn startBtnIndent"></button>
        </div>
      </div>
    </section>
  );
});
